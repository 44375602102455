.parentContainerMembers{
    height:100vh;
    width:100vw;
    position: relative;
    bottom:0px;
  background-color: rgba(250, 250, 250, 1);
    background-position:center;
    z-index: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;


  }
  .pageTitle{
    width:100%;

    font-family: 'Tangerine', sans-serif;
    font-size: 40px;
    text-align: center;
    max-height: 40px;
    position: relative;
    margin-top: 5vh;
    white-space: nowrap;
  }
  .pageTitleMobile{
    width:100%;
    font-family: 'Tangerine', sans-serif;
    font-size: 40px;
    text-align: center;
    margin-top: 5vh;

  
  }
