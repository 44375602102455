.nameText{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    margin:3px;
    flex: 1 0 auto;
    width: 0px;
    text-align: center;
    height: auto;
    font-size: 0px;


}
@keyframes fadeIn{
    0%{
        opacity: .0;

    }

    100%{
        opacity: 1;
     
    }
}
.donationNameParent{
    width: auto;
    margin: 0px;
    
}
.namesContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
   height:60vh;
    min-height: 300px;
    max-height: 70vh;
       
      
    
}
#patronSize{
    color:rgb(0, 0, 0);
    animation: fadeIn 200ms ease-in-out 1;
    animation-direction: alternate;
}
#bronzeSize{
    color: #cd7f32    ;
    animation: fadeIn 200ms ease-in-out 1;
    animation-delay: -50ms;

}
#silverSize{
color: rgb(158, 158, 158);
animation: fadeIn 200ms ease-in-out 1;
    animation-delay: -100ms;
}
#goldSize{
    color: rgba(255,215,0,1);
    animation: fadeIn 200ms ease-in-out 1;
    animation-delay: -150ms;
}
#platinumSize{
   color: 	#65ffff;

}
.thankYouTextParent{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    height:30vh;
   
    color: black;
}
.thankYouHeading{
    font-family: 'Tangerine';
    font-size: clamp(30px, 5vw, 50px);
}
.thankYouParent{

    height: calc(100vh - 50px);
    background: linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
    background-repeat: no-repeat;
    background-size:100vw 50vh;
    background-position:top;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    
}
.thankYouP{
    font-family: 'Times New Roman', Times, serif;
    font-size: clamp(14px, 2vw, 20px);
}