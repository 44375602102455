.eventsParent{
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0px;
  
}
.eventCardContainer{
    height:75vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: baseline;
}
.eventCardContainerMobile{
    height:auto;
    width: auto;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
}
.eventsHeading{

   
        text-align: center;
        font-family: 'Tangerine', sans-serif;
        font-size: max(1.8vw, 40px);
        margin-top:7px;

      
}
.eventsContainer{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: max(1vw, 12px);
}
.downloadLink{
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width:100%;
    
    height:20px;
    border-radius: 40px;
    border:solid black 1px;
    background: #74a59e;
    background-color: rgba(255, 255, 255, 0);
    font-size: clamp(13px, 4vw, 14px);
    font-style: italic;
    color:black;
    margin:5px;
    padding:20px;
  }
  .downloadLink:hover{
  
    text-decoration: underline;
    border: 1px solid grey;
    color: RGB(0, 122, 254);
    
  }