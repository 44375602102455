.parentContainerDonate{
 
    height: calc(95vh - 50px) ;
    width: 100vw;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: none;

   z-index: 0;
   min-height: 450px;

  }
  .donationContainer{
    width:100%;
    height: calc(100vh - 50px) ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
 }
  .donationCardParent{
    width:100%;
    max-height:30vh;
    min-width: 320px;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
  }
  .donationCardParentMobile{
    width:100vw;
    height:100%;
    min-width: 320px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
  }
  .infoCardParent{
    width: 100%;
    min-width: 360px;
    height: 100%;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .infoCardParentMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-width: 360px;
    height: 100%;
    
  }
  .infoCard{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
    height:auto;
    width:95%;
    max-width: 700px;
    min-width: 320px;
    padding:10px;
    margin: 5px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    opacity: 1;

    
  }
  .donationCard{
    height:100%;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color:transparent ;
    display: flex;
    float: column;
    justify-content: center;
    align-items: center;
 
  }
  .donationCardMobile{
    height:100%;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: transparent ;
    display: flex;
    float: column;
    justify-content: center;
    align-items: center;
    
  }
  .donationAmountContainer{
    width:80%;
    background-color:transparent;
    height: calc(100vh / 1.2);
    min-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slideInLeft 200ms 1;
  background-color:white;

  }
  .donationAmountContainerMobile{
    width:100%;
    background-color: white;
    height: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .donorLevelContainer{
    width:100%;
    height:auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 500px;
    min-height: 40px;
    padding:5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    margin:4px;
    border-radius: 10px;
    background-color: white;
  }
  .donorLevelHeading{
    font-size: max(1.8vh, 16px);
    color:#020202;
    width:30%;
    min-width:136px;
    height:auto;
    text-align: center;
    font-family:'Times New Roman', Times, serif;
  
  }
  .donationHeading{
    font-family: 'tangerine';
    font-size: 40px;
  }
  .levelAmount{
  display: inline;

  font-family:'Times New Roman', Times, serif;
  font-size: max(2.2vh,16px);
  font-style: italic;
  }
  .donorLevelPage{
    color: black;
    font-family: 'Tangerine', sans-serif;
    font-size: min(5vh, 60px);
    margin: 10px;
   
  }
  .donorLevelUl{
    margin: 0px;
    width:70%;
    list-style-type:none;
    padding-inline-start: 0px;
    font-size: clamp(10.5px,1.8vh, 16px);
    font-family: 'Times New Roman', Times, serif;
  }
  #platinum{
    
background: white;
background: linear-gradient(90deg, rgba(74,100,108,0.8939950980392157) 1%, rgba(229,228,226,1) 21%, rgba(255,255,255,1) 45%, rgba(255,255,255,1) 100%);
  }
  #gold{
    background: white;
background: linear-gradient(90deg, rgba(255,215,0,1) 0%, rgba(255,255,255,0.5858718487394958) 50%, rgba(255,255,255,1) 100%);
  }
  #silver{
    background: white;
background: linear-gradient(90deg, 	rgba(192,192,192,1) 0%, rgba(255,255,255,0.5858718487394958) 50%, rgba(255,255,255,1) 100%);
  }
  #bronze{
    background:white;
background: linear-gradient(90deg, rgba(205,127,50,1) 0%, rgba(255,255,255,0.5858718487394958) 50%, rgba(255,255,255,1) 100%);
  }
  #patron{
    background:white;
  }
 

  #progressBar{
    margin:20px;
    width: 80%;
    max-width: 500px;
  }
 

  * {
    box-sizing: border-box;
  }
  


  input,
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  
  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }
  .formHeading{
    float: center;
    text-align: center;
    margin:3px;
  }
  .Form {
    animation: fade 200ms ease-out;
    width:100%;
    height:100%;
    min-height: 300px;
    max-width: 700px;
    margin-top: 0px;
    margin:10px;
    position:relative;
  }
 
  .shadowCover{
    width:100%;
    height:45vh;
    background-color: #343a40 ;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .donationInstructions{
    color:white;
    text-align: center;
    padding-left:5px;
    padding-right:5px;
    font-size: min(3.9vw, 16px);
    margin-top: 0px;
    line-height: 140%;
  }
  .comingSoon{
    font-size: 40px;
    text-align: center;
    color: white;
    font-family: "Tangerine";
  }
  
  .FormGroup {
    margin: 0 0px 0px;
    padding: 0;
    margin-top: 5px;
    width:100%;
    max-width: 700px;
    min-width: 300px;
    min-height: 300px;
    height:100%;
    max-height: calc((100vh - 50px) /2);
    border-style: none;
    background-color: white;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;

  }
  
  .FormRow {
   
    width:100%;
    margin-left: 15px;
    border-top: 1px solid #dddcdc;
    max-width: 600px;
  }
  .nameEmailConatiner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width:100%;
    max-width: 600px;
 margin-left: 11px;
   
  }
  .payButtonContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
    width:100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom:15px;
  }
  #FormRowother{
    display: flex;
    border-top: none;
    padding:10px;
    margin:0px;
    justify-content: center;
    align-items: center;
    text-align: center;
   
  }
  #FormRowother::before{
content: '$';

font-size: clamp(30px, 2vw, 40px);
font-family: 'Times New Roman', Times, serif;
  }
 
 
  #other{
    text-align: center;
    padding: 0px;
    margin-top:10px;
    margin-bottom: 10px;
    font-size: clamp(30px, 2vw, 40px);
    font-family: 'Times New Roman', Times, serif;
    width: auto;
    width: 1ch;

  }

  #other::placeholder{
    text-align: center;
  }
  
 
  #FormRowname{
    width:50%;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  #FormRowname::before{
    background-image: url("../icons/userIcon.png");
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
    width: 18px; 
    height: 18px;
    content:"";
    padding: 9px;
    padding-left: 0px;
   margin-right: 8px;
  }

  #name{
    width: 100%;
    margin: 0;
  }
  #FormRowemail{
    width:50%;
    margin:0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #FormRowemail::before{
    background-image: url("../icons/mailIcon.png");
    background-size: 24px 22px;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px; 
    height: 24px;
    content:"";
    padding: 0px;
    padding-left: 0px;
    padding:7px;

  }
  #email{
    width: 100%;
    margin: 0;
    padding-right: 0px;
  }
 
  .creditCardCharge{
    text-align: center;
    display: flex;
    font-size: clamp(10px,1.9vw,12px);
    justify-content: center;
    opacity: .9;
    margin: 7px;
    color:rgb(5, 5, 5);
    border-bottom: solid black 1px;
    padding-bottom: 10px;
    width:70%;
    min-width: 290px;
    justify-content: center;
  }
  .donationInfoParent{
    font-family:Arial, Helvetica, sans-serif ;
    font-size: clamp(11.5px,1.9vw,15px);
    text-align: left;
 
    width: 280px;
    
   padding:10px;
  }
  .instructionParent{
    width: 100%;
    height: 100%;
   
    min-height: 250px;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
position: fixed;
top:0px;
background: rgb(250, 250, 250);
  }
  .donateInPersonButton{
    width:100%;
    color:blue;
    text-align: center;
    background-color: transparent;
    padding: 10px;
    cursor: pointer;
   text-decoration: underline;
  }
  .donateInPersonButton:hover{
    
    color:rgb(13, 13, 14);
    border-bottom: none;
  }
 .instructionHeading{
  width: 100%;
  text-align: center;
  font-family: 'Tangerine', serif;
  font-size: 30px;

  }
  .addressText{
    text-align: left;
    margin-left: 4px;
    font-family:Helvetica ,sans-serif;
    margin-bottom: 0px;
    font-size: 19px;
    margin-top: 4px;
    width:100%
  }
  .addressTitle{
    width:100%;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    opacity: 1;
    text-decoration: underline;
    white-space:nowrap;
  }
  .FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #988c88;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }
  .FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: black;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }
  
  .FormRowInput {

    font-size: 16px;
    width: auto;
    padding: 11px 15px 11px 0;
    color:black;
    background-color: transparent;
    animation: 1ms void-animation-out;
    
  }
  
  .FormRowInput::placeholder {
    color: #dcd9cb;
 
  }
  
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }

  .amountButton{
    display: block;
    font-size: 16px;
    width: 20%;
    height: 40px;
    margin: 0 5px 0;
    background-color: white;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px 4px 0px 0px;
    color: black;
    font-weight: 600;
    cursor: pointer;
    margin-top: 5px;
    will-change: transform, background-color, box-shadow;
  }
  
  .amountButton:active{
    display: block;
    font-size: 16px;
    width: 20%;
    height: 40px;
    margin: 0 5px 0;
    background-color: #ffffff;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px 4px 0px 0px;
    color: black;
    font-weight: 600;
    cursor: pointer;
  }
  @keyframes jump1{
    0%{
      transform: translateX(-30px);
    }
    100%{
      transform: translateX(30px);
    }
  }
  .SubmitButton {
    display: block;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    height: 40px;
    padding: 0px;
    margin: 0 0px 0;
    background-color: #5f7f9e;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #5f7f9e;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
  }
  
  .SubmitButton:active {
    background-color: #8296d9;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #d63dc2;
    transform: scale(0.99);
  }
  
  .SubmitButton.SubmitButton--error {
    transform: scale(0);


  }
  .SubmitButton.SubmitButton--error:active {
    transform: scale(0);
  }
  
  .SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
  }
  
  .ErrorMessage {
    color: #dd4124;
    height:18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    margin: 0px;
    width: 100%;
    max-width: 700px;
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }
  
  .ErrorMessage svg {
    margin-right: 10px;
  }
  
  .Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;


  }
  
  .ResultTitle {
    color: rgb(0 189 23);
    font-weight: 500;
    margin-bottom: 8px;

    text-align: center;
  }
  
  .ResultMessage {
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.6em;
    text-align: center;
  }
  
  .ResetButton {
    opacity: .2;
    border: 0;
    cursor: pointer;
    background: transparent;

  }
 