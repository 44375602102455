.profileParent{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 280px;
    min-width: 150px;
    max-height: 160px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.profilePicture{
    border-radius: 100%;
    height: 50%;
   width:auto;
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   position: relative;
   bottom: 15px;
 
}
.profilePicDiv{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   width:100%;
   text-align: center;
   background-color: rgb(255, 255, 255);

}
.descriptionParent{
    margin:10px;
    width:50%;

}
.title{
    font-family: 'Tangerine', sans-serif;
    font-size: 30px;
    margin:8px;
    word-wrap: none;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    animation: writeText 500ms ease-in 1;
    color: rgba(0, 0, 0, 0.863);
}
.name{
 margin: 0px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 20px;
  


}
@keyframes writeText{
    0%{
        width: 0px;
    }
    100%{
        width: 100%;
    }
}
