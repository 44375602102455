.galleryParentContainer{
    height: calc(100vh - 50px);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
   
}
.photosHeading{
  font-family: 'tangerine', serif;
  font-size: clamp(30px, 10vw, 49px);
  width:100%;
  text-align: center;
  margin-bottom: 5%;
}
.galleryImage{
    width:0px;
    max-width:300px;
    height:auto;
    flex: 0 1 auto;
    margin: 4px;
    box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.11);
    animation-name: grow2;
    animation-duration: 300ms;
    border-radius: 5px;
}
@keyframes grow2{
    0%{
        transform: scale(.2);
    }
    100%{
      transform: scale(1);
    }
}
.galleryImage:hover{
    transform: scale(1.01);
    cursor: pointer;
}
.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding:5px;
  }
  .exitIcon{
    height:32px;
    width:32px;
    position: fixed;
    top:0px;
    right:0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .exitIcon:hover{
    cursor: pointer;
  }
  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .fullGalleryImage{
      width:auto;
      max-width: 90vw;
      height: auto;
      max-height: 80vh;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }