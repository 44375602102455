.progress{
    float: left;
  height: 20px;
  background: #dd4124;
  z-index: 1;
}
.thermometer{
    width: 100%;
    height: 20px;
    background: #c7c7c7;
    border-radius: 10px;
    float: left;
    overflow: hidden;
}
.statText{
  margin:5px;
  font-size: 16px;
}
.stats{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 margin:2px;
 width:30%;

}
.statsParent{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width:100%;
  justify-content: space-around;
  align-items: flex-start;
  font-family:'Times New Roman', Times, serif;
 
  font-size: 18px;
}
#amountRaised{
  font-family:'Times New Roman', Times, serif;
  font-size: 30px;
  text-align: center;
 z-index: 1;
 margin:10px;

}

.progressBarContainer{
 width: 90%;
 max-width: 600px;
}