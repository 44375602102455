/*
heading color: #009874
orange/red : #dd4124
blue: RGB(0, 122, 254)
green: #00987
background grey: #343a40 
*/
body{
  min-height: -webkit-fill-available;
  width:100%;
  height:100vh;
  font-size: 100%;
 
}
.flipPage{
  width:100%;
  height: calc(100vh - 50px);
  min-height: 617px;
  padding:0px;
  position: fixed;
  top:50px;
}
.topPage{
 height: calc(100vh - 50px);
  width:100%;
  background-image: url("./images/oldPaper.jpg");
  background-repeat: no-repeat;
  background-size:100vw 70vh;
  background-color: whitesmoke;
  z-index: 2;
}


.emailContainer{

 width: 100%;
 position: relative;
 display: flex;
 flex-direction: row;
 height:auto;
top:-5vh;
 align-items: center;
 justify-content: center;
 padding:3px;

}
h1{
  margin:0px;

}
a{
color:black

}
.designText{
  font-size: 14px;
  color:black;
  position: fixed;
  bottom:0px;
  left:0px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  opacity: .5;
 margin-left:20px;
 letter-spacing: .5px;
 animation: designText 5s 1 ease-out;
}
@keyframes designText{
  0%{
    opacity: 0;
  }
}
.designMobile{
  font-size: 14px;
  color:black;
  position: relative;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  opacity: .6;
 letter-spacing: .5px;
}
.arrowContainer{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom:0px;
  right:0px;
  height:auto;
  background-color:transparent;
  z-index: 2;
}
.arrow{
width: 30px;
margin:3px;
margin-right: 7px;
opacity: .8;

}
#upArrow{
  animation: wiggle 2.0s 3;
}
#downArrow{
  animation: wiggle 2.0s 3;
  animation-delay: 1000ms;
}
.pageNumber{
  margin-right: 5px;
}
.homeTextParent{
  background-color: #343a40de;
  width:80vw;
  height:auto;
  padding:5vh;
  margin-bottom: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 140%;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 17px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.homeTextParentMobile{
  background-color: #343a40ce;
  width:100vw;
  height:auto;
  padding:5vh;
  margin-bottom: 13vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 140%;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 17px;
  text-align: center;

  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}
.homeHeading{
  text-align: center;
   color: rgb(255, 255, 255);
   font-size: 40px;
   width:50%;
  
   font-family: 'Tangerine', serif;
   font-size: 40px;
   letter-spacing: 0px;
   border-bottom: solid 1px #b3603d;
   padding: 7px;
   white-space: nowrap;

  
 
  
}
@keyframes writeText50{
  0%{
      width: 0px;
      opacity: 0;
  }
  100%{
      width: 50%;
      opacity: 1;
  }
}

.homeP{
  text-align: center;
  color: white;

}
.missionStatement{
  color: white;
  font-size: clamp(10px, 4vw, 20px);
}
.donateButton{
  width:120px;
  height:50px;
  border-radius: 40px;
  border:solid white 1px;
  background: #74a59e;
  background-color: rgba(255, 255, 255, 0);
  font-size: clamp(12px, 4vw, 16px);
  font-weight: bold;
  color:white;
  animation: scaleWidth 400ms 1;
 
}
@keyframes scaleWidth{
0%{
  transform: scaleX(0);
  opacity: 0;
}
100%{
  transform: scaleX(1);
  opacity: 1;
}
}
.donateButton:hover{
 

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0,0.2);
  border: solid 1px RGB(0, 122, 254);
}
.drawing3d{
  background-image: url("./images/3ddrawings4-3.png");
  background-position: top;
  background-position-y: 3vh;
  background-repeat: no-repeat;
  background-size: 50%;
  width:100vw;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}
.drawing3dMobile{
  background-image: url("./images/3ddrawings4-3.png");
  background-position: top;
  background-position-y: 5vh;
  background-repeat: no-repeat;
  background-size: contain;
  width:100vw;
  height:calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  align-items: center;
}

@keyframes wiggle{
  0% { transform: rotate(0deg); }
  50% { transform: rotate(0deg); }
  70% { transform: rotate(0deg); }
  75% { transform: rotate(-10deg); }
  80% { transform: rotate(30deg); 
    transform:translateY(5px)
  }
  85% { transform: rotate(-30deg); 
    transform:translateY(-5px)
  }
  90% { transform: rotate(3deg); }
  95% { transform: rotate(-3deg); }
 100% { transform: rotate(0deg); }
}