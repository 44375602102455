.cardParent{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    margin: 30px;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 white;
  border-radius: 4px;
}


.eventImage{
width:100%;
height:100%;
border-radius: 8px;
}
.eventCardBody{

}
.eventFooter{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dateIcon{
  height:1em;
  width: 1em;
  margin-right: .5em;
}
.dateHeading{

  margin:3px;
  font-style: thin;
  font-weight: normal;
  font-size: clamp(12px, 2vw, 14px); 
}
.iconParent{
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
 
}
.dateContainer{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin:2px;
}
.timeHeading{
  font-style: thin;
  font-weight: normal;
  font-size: clamp(12px, 2vw, 14px); 
 margin: 0;
}
.eventTitle{
  font-size: clamp(20px,2vw, 27px);
  font-family:'Times New Roman', Times, serif;
 margin:5px;
  
}
.informationP{
  font-family: 'Times New Roman', Times, serif;
  font-size: clamp(14px, 2vw, 16px);
}
.locationHeading{
  padding:0px;
  margin:0px;
}