.floorPlanParent2{

    height:100%;
    width:100%;
   display: flex;
   flex-direction: column;
   flex-wrap: none;
   align-items: center;
   justify-content: flex-start;
   text-align: center;
    padding:10px;
    font-family: Cambria,  Times, 'Times New Roman', serif;
    background: url("../images/graaphPaper.jpg");
}

.buildingPlans2Parent{
    height: calc(100vh - 50px);
    background-color: rgb(252, 249, 247);
    z-index: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: none;
    justify-content: flex-start;
    align-items: flex-start;
}
.floorPlanParent3{

    height:50vh;
    width:100%;
   display: flex;
   flex-direction: column;
   flex-wrap: none;
   align-items: center;
   justify-content: flex-start;
   text-align: center;
    padding:10px;
    font-family: Cambria,  Times, 'Times New Roman', serif;
}
.buildingPlans3Parent{
    height: calc(100vh - 50px);
    background-color: rgb(252, 249, 247);
    z-index: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: none;
    justify-content: flex-start;
    align-items: flex-start;
}
.downloadLinkContainer3{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width:100%;
    height:auto;


}