.sponsorsParent{
    height: calc(100vh - 50px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sponsorsContainer{
    width:100%;
    height:auto;
    max-height: 80vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.logoContainer{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    margin: 10px;
}
.logo{
   width:0px;
    height:auto;
    margin: 10px;
    animation-name: grow2;
    animation-duration: 300ms;
    border-radius: 5px;
}
.sponsorsHeading{
    text-align: center;
    font-family: "Tangerine", sans-serif;
    font-size: clamp(30px, 5vw, 50px);
}
.sponsorP{
    font-family: 'Times New Roman', Times, serif;
    font-size: min(2.3vh, 20px);
    text-align: center;
    margin: 0;
}