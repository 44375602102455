.parentContainerBuilding{
    height: calc(100vh - 50px);
    background-color: rgb(252, 249, 247);
    z-index: 0;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    background: url("../images/graaphPaper.jpg");
    align-items: center;
  }
  .parentContainerBuildingMobile{
    height: calc(100vh - 50px);
    background-color: rgb(252, 249, 247);
    background: url("../images/graaphPaper.jpg");
    z-index: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    bottom:0px;


  }
  .carousel{
    height: 100%;
    width: 100%;
    max-width: 100vw;
    height: calc(65vh - 50px);
    min-width:360px;
    z-index:0;
  }
  .carouselParent{
 display: fixed;
    height: 100%;
    min-width:360px;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .slideshowImage{
    height: auto;
    width: 50vw;
    opacity: 1;

  }
  .slideshowImage:hover{
    cursor: pointer;

  }
  .slideshowButton{
    width:24px;
    height:24px;
    opacity:.5;
    margin:5px;
  }
  .slideshowButton:hover{
    width:24px;
    height:24px;
    opacity:.5;
    margin:5px;
    cursor: pointer;
  }
  .arrowContainerSlidehow{
    width:30%;
    position: relative;
 margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
  }
  .arrowContainerSlidehowMobile{
    width:100%;
    position: relative;
    bottom: calc(50% - 14.5px);
    margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
  }
  .pageCounter{
    width:200px;
    color:black;
    margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    margin: 5px;
  }
  .pageCounterMobile{
    color:black;
    position: relative;
    left: calc(50% - 33px);
    bottom:calc(100% - 12px);

  }
  .slideshowImageMobile{
    height: 50vh;
    width: 100vw;
    max-height: calc(100vw*.75);
    position: relative;
    bottom:-2em;
  }
  .carouselParentMobile{
    height:50vh;
    min-width:260px;
    max-height: calc(100vw*.75);
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  
  .designInfoParent{
    min-width: 360px;
    height:100%;
    width: 50vw;
   display: flex;
   flex-direction: column;
  justify-content: center;
  align-items: center;
   
  }
  .buildingP{
        font-size: clamp( 10px,1.8vh, 18px);
    margin:10px;
    text-align: left;
    line-height: 140%;
    font-family: 'Times New Roman', Times, serif;
  }
  .buildingPMobile{

    font-size: clamp(14px, 3.7vw, 20px);
    margin:10px;
    text-align: left;
    line-height: 140%;
    font-family: 'Times New Roman', Times, serif;
  }

  .exteriorParent{
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding:10px;
  }
  .floorPlanParent{

    height:100%;
    width:100%;
   display: flex;
   flex-direction: column;
   flex-wrap: none;
   align-items: center;
   justify-content: center;
   align-items: center;
    padding:10px;
  }
  .floorPlanParentMobile{
    height:100%;
    width:100%;
   display: flex;
   flex-direction: column;
   flex-wrap: none;
   align-items: center;
   justify-content: center;
   text-align: center;
    padding:10px;
    font-family: Cambria,  Times, 'Times New Roman', serif;
 
  }
  .buildingHeader{
   
    text-align: center;
    font-family: 'Tangerine', sans-serif;
    font-size: 40px;
    width:50%;
    min-width: 220px;
    text-align: center;
 

   
  
  }

  .buildingHeaderBorder{

  }
 
  .downloadLinkContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin:0px;
    width:100%;
    min-width: 370px;
  }

  .leftButton{
    position: relative;
   left:calc(-50% + 20px);
   bottom: calc(25% + 30px);
   height:30px;
    opacity: .6;
  }
  .leftButton:hover{
    position: relative;
   left:calc(-50% + 20px);
   bottom: calc(25% + 30px);
   height:31px;
    opacity: 1;
  }

  .rightButton{
    position: relative;
   left:calc(50% - 20px);
   bottom: calc(25% + 60px);
   height:30px;
    opacity: .6;
    transform: rotate(180deg);
  }
  .rightButton:hover{
    position: relative;
    left:calc(50% - 20px);
   bottom: calc(25% + 60px);
   height:31px;
    opacity: 1;
  }
  .downloadIcon{
    height:18px;
    position: relative;
    margin: 2px;
  }
  @keyframes wipeLeft{
    0%{
     
      opacity: 0;
    }
    20%{
      opacity: 1;
    }
    80%{
      opacity: 1;
    }
    100%{
     
      opacity: 0;
    }
  
 
  }