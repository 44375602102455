.navbar {
background-color:#343e49 ;
height:50px;
width: 100vw;
z-index:1;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
position: fixed;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.emailContainerMobile{
    width:100%;
    height:auto;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    padding:0px;
    padding:15px;
    margin: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid grey .5px;


}
.fullNavButton{
    background-color: #4caf4f00;
    width: auto; 
    height:50px;
    border: none;
    color: rgba(255, 254, 254, 0.856);
    text-align: left;
    font-family:'Times New Roman', Times, serif;
    margin:5px;
    font-size: clamp(8px, 1.2vw, 15px);

}
.fullNavButton:focus{
    background-color: #4caf4f00;
    border: none;
    color: white;
    text-align: center;
    border-bottom: #c76e49 solid 2px;

  

}
.fullNavButton:hover{
   transform: translateY(-1px);
   color:white;
   cursor:pointer;
   

}
.toggleBtn{
    height:30px;
    width: 40px;
    padding:10px;
   
}
.fullNavBar{
    display:flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    justify-content: flex-start;
    background-color: transparent;

}
.navButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height:100%;
}
.navButton{
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color: transparent;
    border: none;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    margin:8px;
    height:30px;
    
}
.navButton:hover{
    transform: translateY(-1px);
    cursor:pointer;
}
.navButton:focus{
   color: white
}
.unionGapLogo{

    margin-left: 0px;
    margin-right: 0px;
    width:150px;
    margin:20px;

    border-radius: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.letterHeadFullscreen{
    height:clamp(20px, 4vw, 50px);
    max-height: 50px;
    width: auto;
   margin-right:10px;
   animation-name: slideInRight;
animation-duration: 300ms;
animation-iteration-count: 1;

}
.letterHeadMobile{
    height:40px;
  position: fixed;
   right:0px;
 
   margin-right:9px;
}
.fbLink{
    width:auto;
    height:16px;
    margin:5px;
animation-name: slideInLeft;
animation-duration: 1000ms;
animation-iteration-count: 1;
}
.fbLink:hover{
    cursor:pointer;
}
.fbLinkMobile{
    width:auto;
    height:16px;
    margin:15px;
}
.fredMeyer{
    width: auto;
    height: 40px;
    margin:5px;

}
.fredMeyer:hover{
    cursor: pointer;
}
.fredMeyerMobile{
    width: 80px;
    height: auto;
    margin-right: 0px;
}

.amazonSmileMobile{
width: 80px;
height:auto;
margin: 15px;
}
.socialMediaContainer{
    width: auto;
    height: 40px;
    position: fixed;
    margin-right: 0px;
    right:5px;
    animation-name: slideInLeft;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.amazonSmile{

}
.amazonSmile:hover{
    cursor: pointer;
}
@keyframes slideInLeft{
  
    0%{
        transform: translateX(100%);
    }
}
@keyframes slideInRight{
  
    0%{
        transform: translateX(-200%);
    }
   

}
@keyframes slideInTop{
  
    0%{
        transform: translateY(-100%);
    }
}

