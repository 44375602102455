.alertParent{
    position: fixed;
      z-index: 9;
       top: 50px;
       width: 100vw;
       visibility: visible;
       display: flex;
       flex-direction: row;
       justify-content:flex-start;
       align-items: center;
       background-color:hsl(220deg 85% 85%);
       padding: 5px;
       font-size: 14px;
}
.infoIcon{
   width: 20px;
   height:20px;
   margin:12px;
   margin-right: 16px;

}
.alertHeading{
    margin-bottom: 0px;
    
}
.alertText{
    margin-top: 3px;
}
.alertDismiss{
    height: 16px;
    width: 16px;
    margin:16px;
    margin-left: auto;
}
.alertDismiss:hover{
    cursor: pointer;
}